import axios from "axios";
import { Url } from "src/config";
const leadershipBrandApi ={
    getDashboardDat: async (accessToken, date,teamId) => {
        try {
            const params = {};
    
            if (date) {
                params.date = date;
            }
    
            const response = await axios.get(
                `${Url}/customer/dashboard/leadershipBrand?teamId=${teamId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: params,
                }
            );
    
            return response.data;
        } catch (error) {
            return error;
        }
    },
    
 getQuestionScores : async (accessToken,inviteduser,questionId)=>{
    try {
        const response = await axios.get(`${Url}/customer/dashboard/leadershipBrandSurvey/getQuestionReports?invited_user_id=${inviteduser}&questions_id=${questionId}`,{headers:{
            "access-token": accessToken,
        },});
        return response.data
    } catch (error) {

        return error
    }
 },
 
 getLeadershipBrandStatus : async (accessToken,teamId) => {
    try {
        const response = await axios.get(`${Url}/customer/dashboard/getleadershipBrandStatus?teamId=${teamId}`,{headers:{
            "access-token": accessToken,
        },});
        return response.data
    } catch (error) {

        return error
    }
 }
}
export default leadershipBrandApi;
