/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-10-03 10:50:05
 */
// joyride
import Joyride, { STATUS } from 'react-joyride';
import React, { useState, useEffect } from 'react';
import CustomTooltip from 'src/components/CustomTooltipJoyridenew';
// eslint-disable-next-line
import CryptoJS from 'crypto-js';
import 'src/components/joyride.css';
import { ReactComponent as NavigateStart } from "src/icons/untitled-ui/NavigateStart.svg";


// joyride
import PropTypes from "prop-types";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { AccountButton } from "../account-button";
import { SearchButton } from "../search-button";
import { createTypography } from "src/theme/base/customTypography";
import Divider from "@mui/material/Divider";


// import { saveAs } from "../../../index";
import { CircularProgress } from '@mui/material';
import * as SldButtons from "src/theme/base/SldButtons";
import { ReactComponent as DownloadPageIcon } from "src/icons/untitled-ui/downloadPage.svg";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LanguageIcon from "@mui/icons-material/Language";
import { useNavigate, useLocation } from "react-router-dom";
import { DownloadPagePopUp } from "src/layouts/leadselfDashboard/popUps/downloadPagePopUp";
import { useUser } from "src/hooks/use-user";
import { useTeam } from "src/contexts/team-context";
import teamDashboardResultsApi from "src/services/teamdashboardApi";
import LbsResultsApi from "src/services/leadershipBrandApi";
import { toast } from "react-hot-toast";

import { useSidebar } from 'src/contexts/SidebarContext'; // Import the context hook
// import customerApiService from "src/services/customerApi";
import loginApiService from "src/services/loginVerifyAPI";

const TOP_NAV_HEIGHT = 85;
const SIDE_NAV_WIDTH = 250;
const SIDE_NAV_MINIMIZED_WIDTH = 80; // Adjust the width for minimized state

export const TopNav = (props) => {
  const user = useUser();
  const { selectedTeamId } = useTeam();
  console.log(selectedTeamId, 'selectedTeamId')
  // eslint-disable-next-line  
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isTitledClassAdded, setIsTitledClassAdded] = useState(false);

  const location = useLocation(); // Get current location
  const accessToken = sessionStorage.getItem("accessToken");
 
  const getPermissions = async () => {
    // await customerApiService.getJoyride(accessToken, user.id);
    await loginApiService.getDashboardIds(accessToken, user.id);
    await loginApiService.getSurveyIds(accessToken, user.id);

  };
useEffect(() => {
  getPermissions();
   // eslint-disable-next-line  
}, [])


  const [pageTitle, setPageTitle] = useState('');
  const [dpageTitle, setDpageTitle] = useState('');

  useEffect(() => {
    const path = location.pathname;
    let title;
    switch (path) {
      case '/teamMojo':
        title = 'Team Diagnostics';
        break;
      case '/leadershipBrand':
        title = 'Team Diagnostics';
        break;
      case '/teamDiversity':
        title = 'Team Dynamics';
        break;
      case '/clickWith':
        title = 'Team Dynamics';
        break;
      case '/teamCompatability':
        title = 'Team Dynamics';
        break;
      case '/teamDevelopment':
        title = 'Team Development';
        break;

      default:
        title = 'Team Dashboard';
    }

    setPageTitle(title);

  }, [location.pathname]);
  useEffect(() => {
    const path = location.pathname;
    let title;
    switch (path) {
      case '/teamMojo':
        title = 'Team Mojo';
        break;
      case '/leadershipBrand':
        title = 'LeadershipBrand';
        break;
      case '/teamDiversity':
        title = 'Team Diversity';
        break;
      case '/clickWith':
        title = 'Click-With';
        break;
      case '/teamCompatability':
        title = 'Team Compatability';
        break;
      case '/teamDevelopment':
        title = 'Team Development';
        break;

      default:
        title = 'Team Dashboard';
    }

    setDpageTitle(title);

  }, [location.pathname]);
  const { isMinimized } = useSidebar();
  const navigate = useNavigate();
  const navigateToExplore = () => {
    navigate("/explorerPage");
  };
  const typography = createTypography();
  const { onMobileNavOpen, toptitle, ...other } = props;
  console.log("top tiltle check", toptitle);
  const [isLoading, setIsLoading] = useState(false);
  const [run, setRun] = useState(false);
  // eslint-disable-next-line
  const [stepIndex, setStepIndex] = useState(0);
  const [disableScroll, setDisableScroll] = useState(false);
  const [isAccClassAdded, setIsAccClassAdded] = useState(false);
  const [isWhideClassAdded, setIsWhideClassAdded] = useState(false);
  const [isHideClassAdded, setIsHideClassAdded] = useState(false);
  const [isBodyClassAdded, setIsBodyClassAdded] = useState(false);
  const [isDownoadeContent, setIsDownoadeContent] = useState(false);





  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  // const getShareLink = async (type) => {
  //   try {
  //     setIsLoading(true);
  //     const response = await saveAs(0, type, "Team Dashboard");
  //     setIsLoading(response);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  const isMobile = useMediaQuery("(max-width:600px)");

  // let renderedTitle;
  // switch (toptitle) {
  //   case "Team Mojo":
  //     renderedTitle = "Team Diagnostics";
  //     break;
  //   case "Team Leadership":
  //     renderedTitle = "Team Diagnostics";
  //     break;
  //   case "Team Diversity":
  //     renderedTitle = "Team Dynamics";
  //     break;
  //   case "Click-With":
  //     renderedTitle = "Team Dynamics";
  //     break;
  //   case "Team Compatability":
  //     renderedTitle = "Team Dynamics";
  //     break;
  //   case "Team Charter":
  //     renderedTitle = "Team Development";
  //     break;
  //   default:
  //     // eslint-disable-next-line
  //     renderedTitle = toptitle; // This line should set the default value
  // }

  const checkTeamLeader = async () => {
    console.log("function is calles ---------");
    try {
      const response = await teamDashboardResultsApi.checkTeamLeader(selectedTeamId, user.id, accessToken);
      console.log("response", response);
      if (response.checkedTeamLeader || user.roleId === 1001 || user.roleId === 1000 || user.roleId === 104) {
        setPopupOpen(true);
        setIsLoading(false);
      } else {
        setIsLoading(false)
        toast.error("Only Team leaders are permitted to download and link sharing")
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const [isPopupOpen, setPopupOpen] = useState(false);
  const openPopup = () => {
    checkTeamLeader();
    setIsLoading(true);
    setIsAccClassAdded(true);
    setIsTitledClassAdded(true);
    setIsHideClassAdded(true);
    setIsBodyClassAdded(true);
    setIsDownoadeContent(true);
    setIsWhideClassAdded(true);

  };
  const closePopup = () => {
    setPopupOpen(false);
    setIsAccClassAdded(false);
    setIsTitledClassAdded(false);
    setIsHideClassAdded(false);
    setIsBodyClassAdded(false)
    setIsWhideClassAdded(false);
    setIsDownoadeContent(false);


  };
  // joyride
  // eslint-disable-next-line
  console.log('====================================');
  console.log("location.pathname", location.pathname);
  console.log('====================================');
  const [steps, setSteps] = useState([]);
  // eslint-disable-next-line
  const [triggered, setTriggered] = useState(true);
  useEffect(() => {
    let newSteps = [];

    if (location.pathname === '/leadershipBrand') {
      newSteps = [
        {
          content: (<div>
            <NavigateStart />
            <h2>Welcome to Leadership Brand Survey</h2>
            <p>Take this quick tour to learn how to
              interpret your Leadership Brand Survey results.</p>
          </div>),
          locale: { next: 'Lets go!' },
          placement: 'center',
          target: 'body',
        },
        {
          content: 'View detailed results for each category by clicking into the bars',
          placement: 'auto',
          target: '.joyride-target-lbs1',
        },
        {
          content: 'Answers to the narrative questions are grouped here by theme',
          placement: 'bottom',
          styles: {
            options: {
              width: 300,
            },
          },
          target: '.joyride-target-lbs2',


        },
        {
          content: 'If you have completed a Leadership Brand Survey with us previously, the previous results will be available here for download.',
          placement: 'bottom',
          styles: {
            options: {
              width: 300,
            },
          },
          target: '.joyride-target-lbs3',
          locale: { next: 'Finish' },
          title: 'Last'


        },




      ];
    } else if (location.pathname === '/teamCompatability') {
      newSteps = [
        {
          content: (
            <div>
              <NavigateStart />
              <h2>Welcome to Team Compatibility</h2>
              <p>Take this tour to learn about new features in Team Compatibility</p>
            </div>
          ),
          locale: { next: 'Lets Go!' },
          placement: 'center',
          target: 'body',
        },
        {
          content: 'Discover your Work-With profile here. This content is also accessible in your Personality Profile​',
          placement: 'auto',
          target: '.joyride-target-tc1',
        },
        {
          content: "Explore your team's Work-With preferences here. Scroll down to reveal more team members if some aren't visible.",
          placement: 'auto',
          target: '.joyride-target-tc2',
        },
        {
          content: 'Click on the tiles for further details​',
          placement: 'auto',
          target: '.joyride-target-tc3',
          locale: { next: 'Finish' },
          title: 'Last'
        },

        // Add more steps for another URL
      ];
    }
    else if (location.pathname === '/clickWith') {
      newSteps = [
        {
          content: (
            <div>
              <NavigateStart />
              <h2>Welcome to Click-With</h2>
              <p>Take this tour to learn about new features in Click-With</p>
            </div>
          ),
          locale: { next: 'Lets Go!' },
          placement: 'center',
          target: 'body',
        },


        {
          content: 'Explore team dynamics by selecting your desired team using the Team Selector option',
          placement: 'right',
          target: '.joyride-target-cw1',
        },
        {
          content: 'Get a quick overview of your team’s 4Character distribution. If your team has over 20 members, you will be switched to the search function.',
          placement: 'top-start',
          target: '.joyride-target-cw2',
        },
        {
          content: "Choose two members you would like to view, then hit the 'Click-With' button below",
          placement: 'auto',
          target: '.joyride-target-cw3',
        },
        {
          content: "Here, you'll see a breakdown of your team's personality types",
          placement: 'top-start',
          target: '.joyride-target-cw4',
          title: 'Last'

        },

        // Add more steps for another URL
      ];
    }


    setSteps(newSteps);
  }, [location.pathname]);
  console.log("Steps rendered", steps);

  const handleClickStart = (event, callback) => {
    event.preventDefault();

    // Scroll to the top smoothly
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Delay the filtering and setting of steps
    setTimeout(() => {
      const filteredSteps = steps.filter((step) => document.querySelector(step.target));

      if (filteredSteps.length > 0) {
        setSteps(filteredSteps);
        setRun(true);
      } else {
        console.warn('No valid targets found for Joyride steps.');
      }

      // Invoke the callback if provided
      if (callback && typeof callback === 'function') {
        callback();
      }
    }, 500);
  };
  const dashboardPermsEn = sessionStorage.getItem("dashboardPerms");
  const dashboardPerms = dashboardPermsEn && JSON.parse(CryptoJS.AES.decrypt(dashboardPermsEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8)).map(Number);
  const teamPermsEn = sessionStorage.getItem("teamPerms");
  const teamPerms = teamPermsEn ? JSON.parse(CryptoJS.AES.decrypt(teamPermsEn, user.id.toString()).toString(CryptoJS.enc.Utf8)).map(Number) : [];

  useEffect(() => {
    const checkAndTriggerJoyride = async () => {
      console.log('---function called----');
      const dashboardDiv = document.getElementById('topNavWidth'); // Select the div  
      if (steps.length > 0 && triggered) {
        console.log('---triggered called----');
        const joyrideStatusEn = sessionStorage.getItem("joyrideStatus");
        const joyrideStatus = CryptoJS.AES.decrypt(joyrideStatusEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8);
        const surveyPermsEn = sessionStorage.getItem("surveyPerms");
        const surveyPerms = JSON.parse(CryptoJS.AES.decrypt(surveyPermsEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8)).map(Number);

        console.log('---joyrideStatus--', joyrideStatus);
        console.log('---surveyPerms--', surveyPerms);
        console.log('---teamPerms--', teamPerms);

        const currentPath = window.location.pathname;
        const teamDashboardTdy = await getTeamClickwithStatus();

        const teamDashboardTcy = await getTeamCompatibiltyStatus();
        const teamDashboardLbs = await getLbsStatus();
        let htw = false; // default value

        console.log('dashboardPerms:', dashboardPerms);
        console.log('surveyPerms:', surveyPerms);
        // Set htw based on the current URL path
        if (currentPath === '/leadershipBrand') {
          dashboardDiv.classList.add('no-permision');
          console.log('---inside leadershipBrand if---');
          if (dashboardPerms && dashboardPerms.includes(16)) {
            console.log('teamDashboardLbs', teamDashboardLbs)

            htw = teamDashboardLbs && JSON.parse(joyrideStatus).leadershipBrand === 0;
            if (JSON.parse(joyrideStatus).leadershipBrand === 1) { dashboardDiv.classList.remove('no-permision'); }
            else { dashboardDiv.classList.add('no-permision'); }
            console.log(htw, 'htw lbs')
          }

        } else if (currentPath === '/clickWith') {
          dashboardDiv.classList.add('no-permision');
          if (teamPerms && teamPerms.includes(7)) {
            console.log('teamDashboardTdy', teamDashboardTdy)
            htw = JSON.parse(joyrideStatus).clickWith === 0;
            console.log(JSON.parse(joyrideStatus), 'jr')

            console.log(JSON.parse(joyrideStatus).clickWith, 'htw cw')
            if (JSON.parse(joyrideStatus).clickWith === 1) { dashboardDiv.classList.remove('no-permision'); }
            else { dashboardDiv.classList.add('no-permision'); }
          }
        } else if (currentPath === '/teamCompatability') {
          console.log(currentPath, 'currentPath')
          dashboardDiv.classList.add('no-permision');
          if (teamPerms && teamPerms.includes(8)) {


            htw = JSON.parse(joyrideStatus).teamCompatability === 0;
            console.log('teamDashboardTcy', teamDashboardTcy)
            if (JSON.parse(joyrideStatus).teamCompatability === 1) { dashboardDiv.classList.remove('no-permision'); }
            else { dashboardDiv.classList.add('no-permision'); }
          }
        }

        console.log('htwss:', htw);


        if (htw === true) {
          handleClickStart(new Event('click'));
          dashboardDiv.classList.remove('no-permision');
          console.log('Triggering Joyride...');
        }


        setTriggered(false);
      }

    };

    checkAndTriggerJoyride();

    // eslint-disable-next-line 
  }, [steps, window.location.pathname]);

  useEffect(() => {
    setTriggered(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  const handleJoyrideCallback = (data) => {
    const { status, index } = data;
    console.log('Joyride Callback:', data);
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }

    setStepIndex(index);

    // Disable scrolling for a particular step (example: step 2)
    const scrollBehavior = {
      '/clickWith': {
        enableScrollSteps: [1, 2, 3, 4, 5],
        disableScrollSteps: [0,],
      },

      '/leadershipBrand': {
        enableScrollSteps: [1, 2, 3, 4],
        disableScrollSteps: [0],

      },
      '/teamCompatability': {
        enableScrollSteps: [1, 2, 3],
        disableScrollSteps: [0],

      },
      // Add more URLs and their respective steps as needed
    };

    const currentScrollBehavior = scrollBehavior[location.pathname] || {};

    if (currentScrollBehavior.enableScrollSteps?.includes(index)) {
      setDisableScroll(false);
    } else if (currentScrollBehavior.disableScrollSteps?.includes(index)) {
      setDisableScroll(true);
    } else {
      setDisableScroll(false); // Default behavior if not specified
    }
  };
  const calculateScrollOffset = () => {
    switch (location.pathname) {
      case '/leadershipBrand':
        return 200;
      case '/teamCompatability':
        return 200;
      case '/clickWith':
        return 200;
      default:
        return 0;
    }
  };
  const currentScrollOffset = calculateScrollOffset();

  const getTeamClickwithStatus = async () => {

    try {
      const response = await teamDashboardResultsApi.getTeamDiversityResults(accessToken, user.id, selectedTeamId);
      console.log(selectedTeamId, 'selectedTeamId cjy api')

      if (response && response.data) {
        console.log("Data is loaded successfully:");
        return true; // Data is loaded successfully
      }
      return false; // No data loaded
    } catch (error) {
      console.error("Error loading survey results:", error);
      return false; // Indicate failure to load data
    }
  };
  useEffect(() => {
    if (selectedTeamId) {
      getTeamClickwithStatus();
    }
  },);
  const getTeamCompatibiltyStatus = async () => {
    try {
      const response = await teamDashboardResultsApi.getTeamCompatabilityResults(accessToken, user.id, selectedTeamId);
      console.log(selectedTeamId, 'selectedTeamId tcd api')

      if (response && response.data) {
        console.log("Data is loaded successfully:");
        setDataLoaded(true); // Data is loaded successfully
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error loading survey results:", error);
      return false;
    }
  };

  useEffect(() => {
    if (selectedTeamId) {
      getTeamCompatibiltyStatus();
    }
  },);

  const getLbsStatus = async () => {
    try {
      const response = await LbsResultsApi.getLeadershipBrandStatus(accessToken, selectedTeamId);

      if (response && response.resData && response.resData.resData) {
        console.log(response, 'response getLbsStatus');
        return response.resData.resData.isPublished;
      } else {
        console.log('Invalid response structure', response);
        return false; // Handle case where isPublished is not available
      }

    } catch (error) {
      console.error('Error in getLbsStatus:', error);
      throw error; // Optionally throw the error so it can be handled by the caller
    }
  }

  useEffect(() => {
    if (selectedTeamId) {
      getLbsStatus();
    }
  },);
  useEffect(() => {
    const elements = document.querySelectorAll(".MuiAccordion-root ");
    elements.forEach(element => {
      if (isAccClassAdded) {
        element.classList.add("downloadacc");
      } else {
        element.classList.remove("downloadacc");
      }
    });
  }, [isAccClassAdded]);


    useEffect(() => {
    const elements = document.querySelectorAll(".dhide");
    elements.forEach(element => {
      if (isHideClassAdded) {
        element.classList.add("downloadacc");
      } else {
        element.classList.remove("downloadacc");
      }
    });
  }, [isHideClassAdded]);
  useEffect(() => {
    const elements = document.querySelectorAll(".MuiCardHeader-action");
    elements.forEach(element => {
    if (isBodyClassAdded) {
      element.classList.add('teamDownload');
    } else {
      element.classList.remove('teamDownload');
    }
  });
}, [isBodyClassAdded]);

useEffect(() => {
  const elements = document.querySelectorAll(".downloadContent");
  elements.forEach(element => {
    if (isDownoadeContent) {
      element.classList.add("downloaded");
    } else {
      element.classList.remove("downloaded");
    }
  });
}, [isDownoadeContent]);

  useEffect(() => {
    const elements = document.querySelectorAll(".dwide");
    elements.forEach(element => {
      if (isWhideClassAdded) {
        element.classList.add("downloadacc");
      } else {
        element.classList.remove("downloadacc");
      }
    });
  }, [isWhideClassAdded]);

  useEffect(() => {
    const elements = document.querySelectorAll(".dhide");
    elements.forEach(element => {
      if (isTitledClassAdded) {
        element.classList.add("downloaded");
      } else {
        element.classList.remove("downloaded");
      }
    });
  }, [isTitledClassAdded]);
  // joyride
  return (
    <Box
      component="header"
      id="topNavWidth"
      sx={{
        backdropFilter: "blur(6px)",
        backgroundColor: '#f9f9f9',
        position: "sticky",
        '&.no-permision #downloadButton1': { display: 'none' },
        left: {
          lg: isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH,

        },
        top: 0,
        width: {

          lg: `calc(100% - ${isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="flex-end"
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 6,
          marginBottom: 1.5
        }}
      >
        <Stack alignItems="center"
          direction="row"
          spacing={1}
          sx={{ flexGrow: '2', }}
        >
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}
              className='joyride-target-cwm1'>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          <Typography

            sx={typography.profileHeaing}>{pageTitle}</Typography>
          <SearchButton />
        </Stack>
        {
          location.pathname !== "/clickWith"
          &&
          <Stack alignItems="center"
            direction="row"
            justifyContent="space-around"

            spacing={0}>
            <SldButtons.WhiteButtonSIcon sx={{ flexWrap: 'wrap', display: 'flex' }}
              id="downloadButton"
              variant="outlined"
              onClick={openPopup}
              // onClick={() => getShareLink("pdf")}
              startIcon={isLoading ? <CircularProgress size={20} /> : <DownloadPageIcon />}
            >
              <span>{isMobile ? null : 'Download Page'}</span>
            </SldButtons.WhiteButtonSIcon>
          </Stack>
        }

        <Stack alignItems="center"
          direction="row"
          justifyContent="space-around"

          spacing={0}>           <IconButton
            id="downloadButton1"
            onClick={handleClickStart}>
            <HelpOutlineIcon className='joyride-target-5' />
          </IconButton>


          <>

            <Joyride
              callback={handleJoyrideCallback}
              continuous
              run={run}
              scrollToFirstStep
              disableOverlayClose={true}
              disableCloseOnEsc={true}
              showProgress
              showSkipButton
              scrollOffset={currentScrollOffset}
              steps={steps}
              disableScrolling={disableScroll}
              tooltipComponent={CustomTooltip}
              disableScrollParentFix={true}

              styles={{
                options: {
                  zIndex: 10000,
                },
              }}
            />
          </>
          <IconButton id="downloadButton2"
            onClick={navigateToExplore}>
            <LanguageIcon />
          </IconButton>
          <AccountButton />
        </Stack>

      </Stack><Divider sx={{ mx: 6 }} />
      <DownloadPagePopUp open={isPopupOpen}
        onClose={closePopup}
        surveyId={6}

        fileName={dpageTitle} />
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
