/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:49
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-08-21 05:57:34
 */
export const useUser = () => {

  var item_value = JSON.parse(sessionStorage?.getItem("userDetails"));
  var joyRideDummy = {
    "dashboard": 1,
    "leadSelf": 1,
    "myLeadership": 0,
    "myExecutiveHealth": 0,
    "leadershipBrand": 0,
    "clickWith": 0,
    "teamCompatability": 0,
    "MTP": 0,
    "hranalytics": 0,
    "mtpSettingsTeamSelect": 0,
    "mtpSettings": 0,
    "leadSelfSettings": 0
  }
  var retData = {
    id: item_value?.userId,
    avatar: '/assets/avatars/avatar-anika-visser.png',
    name: item_value?.userFirstName,
    email: item_value?.userEmail,
    firstName: item_value?.userFirstName,
    lastName: item_value?.userLastName,
    authType: item_value?.authType,
    hasIsl: item_value?.has_isl,
    tenetId: Number(item_value?.tenant_id),
    type: item_value?.type,
    changePassword: item_value?.changePassword,
    roleId: Number(item_value?.roleId), 
    // roleId:1000,
    joyRide: JSON.stringify(joyRideDummy)
  }

  return retData
};
