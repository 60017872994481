import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import ChevronRightIcon from "@untitled-ui/icons-react/build/esm/ChevronRight";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import SvgIcon from "@mui/material/SvgIcon";
import TemporaryDrawer from "./teamSelectorDrawer";
import * as SldButtons from "src/theme/base/SldButtons";
import { purple } from "src/theme/colors";
import { forwardRef } from "react";

export const SideNavItem = (props) => {
  const {
    active,
    children,
    depth = 0,
    disabled,
    external,
    icon,
    label,
    open: openProp,
    path,
    title,
    updatetitle,
    isMinimized
  } = props;
  console.log("check cehek ",active );
  const [open, setOpen] = useState(!!openProp);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleTitleClick = useCallback(() => {
    if (title === "Team Selector") {
      setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
    }
  }, [title]);

  useEffect(() => {
    if (!drawerOpen) {
      // Close the drawer when the component unmounts or other conditions
      setDrawerOpen(false);
    }
  }, [drawerOpen]);

  useEffect(() => {
    if (title && active && updatetitle) {
      console.log("tite", title, active);
      updatetitle(title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, active, updatetitle]);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  // Icons can be defined at top level only, deep levels have bullets instead of actual icons.

  let startIcon;
  startIcon = icon;
  //  condition commented to put diff icons for submenu items

  /* if (depth === 0) {
    startIcon = icon;
  } else {
    startIcon = (
      <Box
        sx={{
          alignItems: 'center',
          display: 'center',
          height: 20,
          justifyContent: 'center',
          width: 20
        }}
      >
        <Box
          sx={{
            backgroundColor: purple.main,
            borderRadius: '50%',
            height: 4,
            opacity: 1,
            width: 4,
            ...(active && {
              backgroundColor: 'white',
              height: 6,
              opacity: 1,
              width: 6
            })
          }}
        />
      </Box>
    );
  } */

  const offset = depth === 0 ? 0 : (depth - 1) * 16;

  // Branch

  if (children) {
    return (
      <li>
        <SldButtons.NavbarButton
          disabled={disabled}
          onClick={handleToggle}
          sx={{
            pl: `${16 + offset}px`,

            ...(active && {
              ...(depth === 0 && {
                backgroundColor: `${purple.main} !important`,
                color: `#fff !important`,
              }),
            }),
          }}
        >
          {startIcon && (
            <Box
              component="span"
              sx={{
                marginRight:'2px!important',
                ...(active && {
                  color: `${purple.main} !important`,

                  "& .MuiBox-root .MuiSvgIcon-root": {
                    margin: "0 0px 0 0!important",
                    color: `${purple.main} !important`,
                    "& path": {
                      fill: `${purple.main} !important`,
                    },
                  },
                }),
                "&:hover": {
                  color: "white",
                },
              }}
            >
              {startIcon}
            </Box>
          )}
          {!isMinimized && (<Box
            component="span"
            sx={{
              flexGrow: 1,
              fontFamily: (theme) => theme.typography.fontFamily,
              fontSize: depth > 0 ? 13 : 14,
              ...(active && {
                color: `#fff !important`,
              }),
              ...(disabled && {
                color: "var(--nav-item-disabled-color)",
              }),
            }}
          >
            {title}
          </Box>)}
          <SvgIcon
            sx={{
              fontSize: 16,
            }}
          >
            {open ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </SvgIcon>
        </SldButtons.NavbarButton>
        <Collapse in={open}
sx={{ mt: 0.5 }}>
          {children}
        </Collapse>
      </li>
    );
  }

  // Leaf

  const linkProps = path
    ? external
      ? {
          component: "a",
          href: path,
          target: "_blank",
        }
      : {
          component: forwardRef((props, ref) => (
            <SldButtons.StyledRouterLink
              style={{ marginLeft: "20px", width: "90%" }}
              {...props}
              ref={ref}
              isActive={(match, location) => {
                if (!match) {
                  return false;
                }

                // Add your custom logic to determine if the link should be active
                return match.url === location.pathname;
              }}
            />
          )),
          to: path,
        }
    : {};
  return (
    <li>
      <SldButtons.NavbarButton
        disabled={disabled}
        onClick={handleTitleClick}
        className='joyride-target-cw1'
        sx={{
          pl: `${16 + offset}px`,

          ...(active && {
            ...(depth === 0 && {
              backgroundColor: `${purple.main} !important`,
              color: `#fff !important`,
              '& svg path':{fill:'white'}
            }),
          }),
        }}
        {...linkProps}
      >
        {startIcon && (
          <Box
            component="span"
            sx={{
              marginRight:'2px!important',
              ...(active && {
                color: `#fff !important`,
                '& svg path':{fill:'white'}
              }),
            }}
          >
            {startIcon}
          </Box>
        )}
         {!isMinimized && (<Box
          component="span"
          sx={{
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: depth > 0 ? 13 : 14,

            ...(active && {
              color: `#fff !important`,
            }),
            ...(disabled && {
              color: "var(--nav-item-disabled-color)",
            }),
          }}
        >
          {title}
        </Box>)}
        {label && !isMinimized &&(
          <Box component="span"
sx={{ ml: 2 }}>
            {label}
          </Box>
        )}
      </SldButtons.NavbarButton>
      {drawerOpen && <TemporaryDrawer />}
    </li>
  );
};

SideNavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  icon: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  isMinimized: PropTypes.bool.isRequired,
};
