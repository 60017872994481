/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:49
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-08-01 22:25:20
 */
export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const auth0Config = {
  base_url: process.env.REACT_APP_AUTH0_BASE_URL,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  issuer_base_url: process.env.REACT_APP_AUTH0_ISSUER_BASE_URL,
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

export const mapboxConfig = {
  apiKey: process.env.REACT_APP_MAPBOX_API_KEY,
};
export let Url = "";
if (window.location.host === "dev-customer.sl-ivl.com") {
  Url = "https://1qp1gktax0.execute-api.ap-southeast-2.amazonaws.com/dev";
} else if (window.location.host === "dev-customer-datamigration.sl-ivl.com") {
  Url = "https://az56cn4erf.execute-api.ap-southeast-2.amazonaws.com/dev";
} else if (window.location.host === "sl-load-test-customer.sl-ivl.com") {
  Url = "https://2swud8yia0.execute-api.ap-southeast-2.amazonaws.com/dev";
} else if (window.location.host === "stage-customer.sl-ivl.com") {
  Url = "https://caelf452vf.execute-api.ap-southeast-2.amazonaws.com/stage";
} else if (window.location.host === "qa-customer.sl-ivl.com") {
  Url = "https://nj2xyvrk7h.execute-api.ap-southeast-2.amazonaws.com/test";
} else if (window.location.host === "train-customer.sl-ivl.com") {
  Url = "https://hk3z6pu5c1.execute-api.ap-southeast-2.amazonaws.com/dev";
}else if (window.location.host === "test.sl-ivl.com") {
  Url = "https://test.sl-ivl.com/api";
} else if (window.location.host === "stage.sl-ivl.com") {
  Url = "https://stage.sl-ivl.com/api";
}else if (window.location.host === "integratedtesting-customer.sl-ivl.com") {
  Url = "https://lpy48q0ygi.execute-api.ap-southeast-2.amazonaws.com/dev";
}else if (window.location.host === "prod-customer.sl-ivl.com") {
  Url = "https://prod-api.sl-ivl.com";
}
 else {
  Url = "http://localhost:8080";
}

export let CmsUrl = "https://sld-strapi.sl-ivl.com/api";
export let explorerImageUrl = "https://sld-strapi.sl-ivl.com";
export let bearerToken = "d6d619c3f797679078b73e36d74119e61871ac381df0b404c91c735981310acc5f8604657058f498ed0479f57e6668d2678941b58ea7e2974739813ce44e76b868b6a515cd60fb5be79708de41a36100ac15dea63d5cf614d9dda5371727c639c9ef31b4fed536a489a7f58f5e601b47d676f0b4299089c14ec8bbc57f80e291";
export const siteConstant = {
  AZURE_CONFIG_STORAGE_KEY: "azure-config",
  AUTH_ISSUER_STORAGE_KEY: "issuer",
  AUTH_TOKEN_STORAGE_KEY: "accessToken",
  AUTH_PROFILE_STORAGE_KEY: "userDetails",
};

export const RECAPTCHA_KEY = "6LceKHEpAAAAAIHsclSv3YMyeq_eiAe5B3WwOXuN";
